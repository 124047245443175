<template>
  <div v-if="pagesList.length > 0" >
    <item
      v-for="item in pagesList"
      :key="item.id"
      :item="item"
    />
  </div>
</template>

<script>
import item from './item'

export default {
  components: {
    item
  },
  computed: {
    pagesList () {
      return this.$store.state.search.pagesList
    }
  }
}
</script>
