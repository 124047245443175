import Vue from 'vue'
import Vuex from 'vuex'
import events from '@/store/events'
import search from '@/store/search'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    DjangoApp
  },
  modules: {
    events,
    search
  }
})
