<template>
  <search />
</template>

<script>
import Search from '@/views/search'
export default {
  components: {
    Search
  }
}
</script>
