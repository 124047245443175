<template>
  <div class="external-link-modal modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-body">
          <div class="introduction">
              <h3 class="title"> {{ translations.EXT_LINK_HEADLINE }}</h3>
              <p class="text">{{ translations.EXT_LINK_TEXT }}</p>
          </div>
        </div>
        <div class="modal-footer">
          <a :href="this.url" class="button">Ok</a>
          <button class="button" @click="$parent.closeModal()">{{ translations.CANCEL }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      url: null
    },
    computed: {
      translations () {
        return this.$store.state.DjangoApp.settings.TRANSLATIONS
      }
    }
  }
</script>
