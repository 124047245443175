<template>
  <div class="cell text-center month-switch-row">
    <div class="month-switch">
      <ul class="month-switch-list menu-pipes">
        <hooper ref="carousel" :settings="hooperSettings">
            <slide
              v-for="(month, index) in months"
              :key="index"
            >
              <a :class="{ active: activeMonth.month() === month.date.month() }" @click="setFilterMonth(month.date)">
                {{ month.name.slice(0, 3) }}
              </a>
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </ul>
    </div>
    <div v-if="isSchauspiele" class="schauspiel-message">{{ translations.WEITERE_SCHAUSPIEL_TERMINE }}</div>
  </div>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import { getTodayBeginningOfTheMonth, getDateBeginningOfTheMonth, moment } from '@/helpers'
export default {
  data: () => {
    return {
      hooperSettings: {
        itemsToShow: 1,
        itemsToSlide: 1,
        mouseDrag: false,
        trimWhiteSpace: true,
        breakpoints: {
          768: {
            itemsToShow: 3,
            itemsToSlide: 3
          }
        }
      }
    }
  },
  components: { Slide, Hooper, HooperNavigation },
  computed: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    },
    months () {
      return this.$store.state.events.filters.months
    },
    monthNames () {
      return this.$store.state.events.filters.monthNames
    },
    activeMonth () {
      return moment(this.$store.state.events.query.month)
    },
    isSchauspiele () {
      const thirdMonth = getTodayBeginningOfTheMonth()
      thirdMonth.add(2, 'months')
      return this.activeMonth >= getDateBeginningOfTheMonth(thirdMonth)
    }
  },
  methods: {
    setFilterMonth (date) {
      this.$store.dispatch('filter', {
        mutations: ['setFilterMonth'],
        value: moment(date).format('YYYY-MM-DD')
      })
    },
    slidePrev () {
      this.$refs.carousel.slidePrev()
    },
    slideNext () {
      this.$refs.carousel.slideNext()
    }
  }
}
</script>
