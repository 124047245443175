<template>
  <div class="head-item dropdown-container" v-bind:class="{ open: isOpen }">
    <button class="open-search open-dropdown" v-on:click="openSearchForm">
      Suche
    </button>
    <div class="dropdown-content dropdown-search">
      <button class="button-menu-close close-dropdown" title="Schließen" v-on:click="closeSearchForm">
        <span class="show-for-sr">Schließen</span>
      </button>
      <div class="search-head">
        <form>
          <label for="search-input" class="search-label" id="searchLabel"><span class="show-for-sr">Suchen</span></label>
          <input type="text" id="search-input" name="searchtext" class="search-input" placeholder="Ihre Suche"
                 ref="searchText" v-on:input="searchTextChanged" v-on:keydown.enter.prevent="searchTextChanged"/>
          <button type="reset" class="search-reset"><span class="show-for-sr">Zurücksetzen</span></button>
        </form>
      </div>
      <div class="search-results" v-if="areResultsVisible">
        <div class="grid-container text-left">
          <div class="grid-x grid-margin-x align-center">
            <div class="cell xlarge-3 anchor-wrap">
              <ul class="vertical medium-horizontal xlarge-vertical menu align-center anchor-nav">
                <li>
                  <a href="#produktionen" class="jump">Produktionen (<span class="tb-red">{{ productionsCount }}</span>)</a>
                </li>
                <li>
                  <a href="#seiten" class="jump">Seiten (<span class="tb-red">{{ pagesCount }}</span>)</a>
                </li>
              </ul>
            </div>

            <div class="cell xlarge-8">

              <!-- results: productions -->
              <h2 id="produktionen" v-if="productionsCount">Produktionen</h2>
              <search-productions/>
              <div class="spacer" v-if="productionsCount"></div>
              <!-- / results: productions -->

              <!-- results: pages -->
              <h2 id="seiten" v-if="pagesCount">Seiten</h2>
              <search-pages/>
              <div class="spacer" v-if="pagesCount"></div>
              <!-- / results: pages -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SearchProductions from '@/components/search-productions'
  import SearchPages from '@/components/search-pages'
  import _ from 'lodash'

  export default {
    components: {
      SearchProductions,
      SearchPages
    },
    data: function () {
      return {
        isOpen: false,
        searchText: ''
      }
    },
    computed: {
      productionsCount () {
        return this.$store.state.search.productionsList.length
      },
      pagesCount () {
        return this.$store.state.search.pagesList.length
      },
      areResultsVisible () {
        return this.searchText.length > 2
      }
    },
    methods: {
      searchTextChanged: _.debounce(function (event) {
        this.searchText = event.target.value
        this.$store.dispatch('UpdateSearchText', event.target.value)
        var html = document.getElementById('html-id')
        if (this.areResultsVisible) {
          html.classList.add('search-results-visible')
        } else {
          html.classList.remove('search-results-visible')
        }
      }, 1000),
      openSearchForm: function (event) {
        this.isOpen = true
        this.$refs.searchText.focus()
        setTimeout(() => {
          let label = document.getElementById('searchLabel')
          label.focus()
        }, 200)
      },
      closeSearchForm: function (event) {
        this.isOpen = false
        var html = document.getElementById('html-id')
        html.classList.remove('search-results-visible')
      }
    }
  }
</script>
