<template>
  <ul class="page-switch">
    <li><a :class="{ active: isCalendar }" @click="changeMode('kalender')">{{ translations.CALENDAR }}</a></li>
    <li><a :class="{ active: isRepertoire }" @click="changeMode('repertoire')">{{ translations.REPERTOIRE }}</a></li>
  </ul>
</template>
<script>
export default {
  computed: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    },
    language () {
      return this.$store.state.DjangoApp.settings.LANGUAGE_CODE
    },
    isCalendar () {
      return this.$route.query.mode === 'kalender'
    },
    isRepertoire () {
      return this.$route.query.mode === 'repertoire'
    }
  },
  methods: {
    changeMode (mode) {
      this.$store.dispatch('filter', {
        mutations: ['setMode', 'setShowItems'],
        value: { mode }
      })
    }
  }
}
</script>
