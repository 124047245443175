<template>
  <div class="event-item grid-x grid-margin-x align-middle">
    <a
      :href="item.url"
      class="event-link cell auto align-middle grid-x grid-margin-x"
    >
      <div class="cell text-center date-info align-self-middle">
        <div class="subhead">{{ item.date_weekday }}</div>
        <div class="h3">{{ item.date_day }}</div>
        <div class="subhead">{{ item.date_month }}</div>
      </div>
      <div class="cell show-for-medium event-image">
        <img :src="item.img_src" :alt="item.img_alt" />
      </div>
      <div class="cell auto">
        <div class="subhead location">
          <span v-if="item.status === 'premiere'" class="premiere">
            <strong>{{ translations.PREMIERE }}</strong>
          </span>
          <span v-if="item.status === 'world-premiere'" class="premiere">
            <strong>{{ translations.WORLD_PREMIERE }}</strong>
          </span>
          <span v-if="item.status === 'wiederaufnahme'" class="premiere">
            <strong>{{ translations.WIEDERAUFNAHME }}</strong>
          </span>
          <span v-if="item.status === 'einfuehrungsmatinee'" class="premiere">
            <strong>{{ translations.EINFUEHRUNGSMATINEE }}</strong>
          </span>
          <span v-if="item.status === 'zum-letzten-mal'" class="premiere">
            <strong>{{ translations.ZUM_LETZTEN_MAL }}</strong>
          </span>
          <span v-if="item.status === 'gastspiel'" class="premiere">
            <strong>{{ translations.GASTSPIEL }}</strong>
          </span>
          <span v-for="(tag, index) in item.tags" :key="index">{{ tag }}</span>
          <span>{{ item.date_time }}</span>
        </div>
        <div class="h3 event-title">
          {{ item.title }}
        </div>
        <div v-html="item.description" class="subtitle">
        </div>
        <div v-if="cancelled" class="subtitle warning">
          {{ item.ticket.message }}
        </div>
        <div v-if="item.begleitveranstaltung" class="begleitveranstaltung" v-html="item.begleitveranstaltung"></div>
      </div>
    </a>
    <div v-if="noTicketStatus" class="cell ticket-status noticketstatus">
      <a v-if="item.ticket.url" :href="item.ticket.url" @click.prevent="$emit('openModal', item.ticket.url)" class="button medium-expanded">{{ translations.BUY_TICKET }}</a>
    </div>
    <div v-if="soldOut" class="cell ticket-status">
      <span class="button disabled hollow gray medium-expanded">
        {{ translations.BUY_TICKET }}
        <span class="no-tickets">{{ translations.SOLD_OUT }}</span>
      </span>
    </div>
    <div v-if="item.ticket.status === 'disabled'" class="cell ticket-status">
      <span class="button disabled hollow gray medium-expanded">
        {{ translations.BUY_TICKET }}
        <span class="no-tickets">{{ item.ticket.message }}</span>
      </span>
    </div>
    <div v-if="cancelled" class="cell ticket-status">
      <span class="button disabled hollow gray medium-expanded">
        {{ translations.BUY_TICKET }}
        <span class="no-tickets">{{ translations.CANCELLED }}</span>
      </span>
    </div>
    <div v-if="ticketsFrom" class="cell ticket-status">
      <span class="button clear medium-expanded">
        {{ ticketStatusMessage }}
      </span>
    </div>
    <div v-if="item.ticket.status === 'restkarten'" class="cell ticket-status">
      <a :href="item.ticket.url" class="button medium-expanded">
        {{ translations.BUY_TICKET }}
        <span class="no-tickets">{{ translations.RESTKARTEN }}</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    },
    cancelled () {
      return this.item.ticket.status === 'cancelled'
    },
    soldOut () {
      return this.item.ticket.status === 'sold-out'
    },
    ticketsFrom () {
      return this.item.ticket.status === 'tickets-from'
    },
    noTicketStatus () {
      return this.item.ticket.status === null
    },
    ticketStatusMessage () {
      return this.item.ticket.message
    }
  }
}
</script>
