import axios from 'axios'
import router from '@/router'

import { generateMonthsArrayForSeasons, isInMonth, isFuture } from '@/helpers'

const isTest = process.env.NODE_ENV === 'test'
const API_URL = isTest ? `/fixtures/api` : `/${DjangoApp.settings.LANGUAGE_CODE}/api`

export default {
  state: {
    animate: false,
    list: [],
    repertoireList: [],
    filters: {
      categories: [],
      seasons: [],
      months: [],
      // Temporarily because https://dev.mirserver.de/theater-bonn/theater-bonn/-/issues/335
      // showItems: window.innerWidth >= 1280 ? 9 : 8
      showItems: 7
    },
    query: {
      // Temporarily because https://dev.mirserver.de/theater-bonn/theater-bonn/-/issues/335
      // mode: 'repertoire',
      mode: 'kalender',
      month: undefined,
      season_start: undefined,
      season_end: undefined,
      category: undefined,
      is_premiere: undefined,
      // Temporarily because https://dev.mirserver.de/theater-bonn/theater-bonn/-/issues/335
      // showItems: window.innerWidth >= 1280 ? 9 : 8
      showItems: 7
    }
  },
  mutations: {
    // Lists
    setEventList (state, payload) {
      state.list = payload
    },
    setRepertoireList (state, payload) {
      state.repertoireList = payload
    },
    setCategorySwitcher (state, payload) {
      state.filters.categories = payload
    },
    setMonthsSwitcher (state, payload) {
      const monthArray = generateMonthsArrayForSeasons(state.filters.seasons, payload)
      state.filters.months = monthArray.filter(
        item => isInMonth(item.date) || isFuture(item.date)
      )
    },
    setSeasonSwitcher (state, payload) {
      state.filters.seasons = payload
    },
    // Show-more
    resetActiveShowItems (state) {
      state.query.showItems = state.filters.showItems
    },
    setShowItems (state, payload) {
      state.filters.showItems = payload.mode === 'kalender' ? 7 : window.innerWidth >= 1280 ? 9 : 8
    },
    // Filters
    setFilterCategory (state, payload) {
      state.query.category = payload
    },
    setFilterMonth (state, payload) {
      state.query.month = payload
    },
    setFilterSeason (state, payload) {
      state.query.season_end = payload.end
      state.query.season_start = payload.start
    },
    setMode (state, payload) {
      state.query.mode = payload.mode
    },
    toggleFilterPremiere (state) {
      state.query.is_premiere = !state.query.is_premiere
    },
    updateQueryParameters (state) {
      const query = state.query
      router.push({ query }, () => {})
    },
    animateList (state) {
      state.animate = true
      setTimeout(() => {
        state.animate = false
      }, 500)
    },
    scrollToTopEvents () {
      const scrollToElement = document.getElementById('filters-scroll-to')
      const headerContainer = document.getElementsByClassName('header-container')
      if (headerContainer.length === 0) {
        return
      }
      const scrollTopPosition = scrollToElement.offsetTop + scrollToElement.getBoundingClientRect().height - headerContainer[0].getBoundingClientRect().height

      window.scrollTo({
        top: scrollTopPosition,
        left: 0,
        behavior: 'smooth'
      })
    }
  },
  actions: {
    async fetchFilters ({ commit }) {
      const seasons = await axios.get(`${API_URL}/seasons/`)
      const monthnames = await axios.get(`${API_URL}/monthnames/`)
      const categories = await axios.get(`${API_URL}/categories/`)
      commit('setCategorySwitcher', categories.data)
      commit('setSeasonSwitcher', seasons.data)
      commit('setFilterSeason', seasons.data.find(s => s.main) || seasons.data[0])
      commit('setMonthsSwitcher', monthnames.data)
    },
    async fetchEventList ({ commit }) {
      const events = await axios.get(`${API_URL}/events/`)
      commit('setEventList', events.data)
    },
    async fetchRepeitoreList ({ commit }) {
      const repertoire = await axios.get(`${API_URL}/repertoire/`)
      commit('setRepertoireList', repertoire.data)
    },
    filter ({ state, commit }, payload) {
      commit('scrollToTopEvents')
      setTimeout(() => {
        if (payload.mutations && !state.animate) {
          if (!payload.mutations.includes('setMode')) {
            commit('animateList')
          }
          setTimeout(() => {
            payload.mutations.forEach(mutation => {
              commit(mutation, payload.value)
            })
            commit('resetActiveShowItems')
            commit('updateQueryParameters')
          }, 250)
        }
      }, 250)
    },
    initQueries ({ state, commit }, payload) {
      const query = state.query
      payload.is_premiere = payload.is_premiere === 'true'
      // Temporarily because https://dev.mirserver.de/theater-bonn/theater-bonn/-/issues/335
      // payload.showItems = payload.showItems ? parseInt(payload.showItems) : payload.mode === 'kalender' ? 7 : window.innerWidth >= 1280 ? 9 : 8
      payload.showItems = payload.showItems ? parseInt(payload.showItems) : 7
      payload.month = payload.month ? payload.month : undefined
      state.query = { ...query, ...payload }
      commit('updateQueryParameters')
    },
    showMoreItems ({ state, commit }) {
      state.query.showItems += state.filters.showItems
      commit('updateQueryParameters')
    }
  }
}
