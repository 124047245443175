<template>
  <div class="cell text-center season-switch-row">
    <div class="season-switch">
      <span>{{ translations.SEASON }}</span>
      <ul class="season-switch-list menu-pipes">
        <hooper ref="carousel" :settings="hooperSettings">
            <slide
              v-for="(season, index) in seasons"
              :key="index"
            >
              <a
                :class="{ active: season_end === season.end }"
                @click="setFilterSeason(season)">
                {{ season.title }}
              </a>
            </slide>
            <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </ul>
    </div>
  </div>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'

export default {
  components: { Slide, Hooper, HooperNavigation },

  computed: {
    seasons () {
      return this.$store.state.events.filters.seasons
    },
    season_end () {
      return this.$store.state.events.query.season_end
    },
    hooperSettings () {
      console.log(this.seasons.length)
      return {
        itemsToShow: 1,
        itemsToSlide: 1,
        mouseDrag: false,
        trimWhiteSpace: true,
        breakpoints: {
          768: {
            itemsToShow: 3,
            itemsToSlide: 3
          }
        }
      }
    }
  },

  methods: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    },
    setFilterSeason (season) {
      this.$store.dispatch('filter', {
        mutations: ['setFilterSeason'],
        value: season
      })
    },
  }
}
</script>
