<template>
    <div class="grid-x grid-margin-x grid-margin-y events-grid" v-if="productionsList.length > 0" >
      <item css_classes="cell medium-6"
        v-for="item in productionsList"
        :key="item.id"
        :item="item"
      />
    </div>
</template>

<script>
import item from '@/components/repertoire/item'

export default {
  components: {
    item
  },
  computed: {
    productionsList () {
      return this.$store.state.search.productionsList
    }
  }
}
</script>
