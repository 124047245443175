<template>
  <div class="grid-x grid-margin-x grid-margin-y align-center">
    <div class="cell medium-6 xlarge-5 text-center">
      <button @click="emitShowMore" class="load-more-events load-more-items button hollow expanded">
        {{ translations.LOAD_MORE }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    emitShowMore () {
      this.$emit('show-more')
    }
  },
  computed: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    }
  }
}
</script>
