<template>
  <div
    :class="{ open: isOpen }"
    class="dropdown-container filter-select-container"
  >
    <button
      class="open-dropdown open-filter button hollow expanded"
      @click="toggle"
    >
      {{ categoryFilter ? categoryFilter : translations.CATEGORY_ALL }}
    </button>
    <div class="filter-select-content">
      <ul class="category-filters menu medium-horizontal">
        <li @click="setFilterCategory(null)">
          <a :class="{ active: !categoryFilter }">{{ translations.CATEGORY_ALL }}</a>
        </li>
        <li
          v-for="(category, index) in categories"
          :key="index"
          @click="setFilterCategory(category.value)"
        >
          <a :class="{ active: category.value === categoryFilter }">
            {{ category.title }}
          </a>
        </li>
      </ul>
      <input
        id="just-premieres"
        :value="premiereFilter"
        type="checkbox"
        class="input-hidden"
        @click="toggleFilterPremiere"
      />
      <label
        for="just-premieres"
        :class="{ active: premiereFilter }"
      >{{ translations.ONLY_PREMIERE }}</label>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    },
    categories () {
      return this.$store.state.events.filters.categories
    },
    activeFilters () {
      return this.$store.state.events.filters.active
    },
    categoryFilter () {
      return this.$route.query.category
    },
    premiereFilter () {
      return this.$store.state.events.query.is_premiere
    }
  },
  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },
    toggleFilterPremiere () {
      this.$store.dispatch('filter', {
        mutations: ['toggleFilterPremiere']
      })
      this.isOpen = false
    },
    setFilterCategory (category) {
      this.$store.dispatch('filter', {
        mutations: ['setFilterCategory'],
        value: category
      })
      this.isOpen = false
    }
  }
}
</script>
