<template>
  <div class="result-item result-item-pages">
    <a :href="item.url">
      <h3>{{ item.title }}</h3>
      <p v-html="item.raw_content"></p>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    },
    dir_static () {
      return this.$store.state.DjangoApp.settings.STATIC_URL
    }
  }
}
</script>
