import axios from 'axios'

const isTest = process.env.NODE_ENV === 'test'
const API_URL = isTest ? `/fixtures/api` : `/${DjangoApp.settings.LANGUAGE_CODE}/api`

export default {
  state: {
    productionsList: [],
    pagesList: [],
    query: {
      searchText: ''
    }
  },
  mutations: {
    setProductionsList (state, payload) {
      state.productionsList = payload
    },
    setPagesList (state, payload) {
      state.pagesList = payload
    },
    setSearchText (state, payload) {
      state.query.searchText = payload
    }
  },
  actions: {
    async UpdateSearchText ({ commit }, payload) {
      commit('setSearchText', payload)
      if (payload.length < 3) {
        return
      }
      const productionsList = await axios.get(`${API_URL}/search-productions/` + this.state.search.query.searchText + `/`)
      const pagesList = await axios.get(`${API_URL}/search-pages/` + this.state.search.query.searchText + `/`)
      commit('setProductionsList', productionsList.data)
      commit('setPagesList', pagesList.data)
    }
  }
}
