<template>
  <div class="cell">
    <div v-if="repertoireList.length === 0" class="grid-x align-center">
      <loading/>
    </div>
    <div v-if="repertoireList.length > 0" :class="{'fade-in-out-animation': animate}" class="grid-x grid-margin-x grid-margin-y events-grid">
      <item  css_classes="cell small-12 medium-6 xlarge-4"
        v-for="item in slicedRepertoireItems"
        :key="item.id"
        :item="item"
      />
    </div>
    <load-more-items v-if="this.repertoireItems.length > showItems" @show-more="showMore"/>
  </div>
</template>
<script>
import { isBetweenDateRange } from '@/helpers'
import item from './item'
import loadMoreItems from '@/components/loadMoreItems'
import loading from '@/components/loading'

export default {
  layout: 'repertoire',
  methods: {
    showMore () {
      this.$store.dispatch('showMoreItems')
    }
  },
  components: {
    item,
    loadMoreItems,
    loading
  },
  computed: {
    animate () {
      return this.$store.state.events.animate
    },
    showItems () {
      return this.$store.state.events.query.showItems
    },
    repertoireItems () {
      return this.repertoireList
        .filter(
          (item) =>
            !this.premiereFilter || item.is_premiere === this.premiereFilter
        )
        .filter(
          (item) =>
            isBetweenDateRange(
              item.date,
              this.seasonStart,
              this.seasonEnd
            )
        )
        .filter(
          (item) =>
            !this.categoryFilter ||
            item.categories.includes(this.categoryFilter)
        )
    },
    slicedRepertoireItems () {
      return this.repertoireItems.slice(0, this.showItems)
    },
    repertoireList () {
      return this.$store.state.events.repertoireList
    },
    seasonStart () {
      return this.$store.state.events.query.season_start
    },
    seasonEnd () {
      return this.$store.state.events.query.season_end
    },
    categoryFilter () {
      return this.$store.state.events.query.category
    },
    premiereFilter () {
      return this.$store.state.events.query.is_premiere
    }
  }
}
</script>
