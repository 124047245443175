<template>
  <div v-if="eilmeldung" class="flash-message-container">
    <p class="flash-message">
      <span class="title">{{ eilmeldung.titel }}</span> {{ eilmeldung.text }}
      <a v-if="eilmeldung.link" :href="eilmeldung.link">{{ eilmeldung_link_titel }}</a>
    </p>
  </div>
</template>

<script>

export default {
  computed: {
    eilmeldung () {
      return this.$store.state.DjangoApp.eilmeldung
    },
    eilmeldung_link_titel () {
      return this.$store.state.DjangoApp.eilmeldung.link_titel || 'Weiterlesen'
    }
  }
}
</script>
