import Vue from 'vue'
import App from './App.vue'
import Search from './Search.vue'
import router from './router'
import store from './store'
// import './registerServiceWorker'

Vue.config.productionTip = false

if (document.getElementById('events')) {
  // do it only if eventlist present on the page, currently only home page
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#events')
}

new Vue({
  store,
  render: h => h(Search)
}).$mount('#search')
