<template>
  <div class="grid-container events-container">
    <div class="grid-x grid-margin-x align-center">
      <div class="cell">
        <h1 id="filters-scroll-to" class="text-center">{{ translations.PROGRAMM }}</h1>
      </div>
      <div class="cell grid-x align-left filter-row">
        <page-switch />
        <category-switch />
      </div>
      <calendar v-if="isCalendar" />
      <repertoire v-if="isRepertoire" />
    </div>
  </div>
</template>

<script>
  import PageSwitch from '@/components/filter/page'
  import CategorySwitch from '@/components/filter/category'
  import Calendar from '@/views/calendar'
  import Repertoire from '@/views/repertoire'

  export default {
    components: {
      Calendar,
      Repertoire,
      PageSwitch,
      CategorySwitch
    },
    mounted () {
      this.$store.dispatch('initQueries', this.$route.query)
      this.$store.dispatch('fetchFilters')
    },
    computed: {
      isCalendar () {
        return this.$route.query.mode === 'kalender'
      },
      isRepertoire () {
        return this.$route.query.mode === 'repertoire'
    },
      translations () {
        return this.$store.state.DjangoApp.settings.TRANSLATIONS
      }
    }
  }
</script>
