<template>
  <div :class="css_classes">
   <a class="grid-teaser" :href="item.url">
      <div class="image">

    <!-- .play-button -->
    <div v-if="item.video_url" :href="item.video_url" data-fancybox class="play-button">
      <span class="video-icon"><span class="show-for-sr">Video abspielen</span></span>
    </div>
    <!-- /.play-button -->

        <img :src="item.img_src" :alt="item.img_alt" :class="{ grayscale: item.past }" />
      </div>
      <div class="text">
        <p v-if="item.status === 'world-premiere'" class="subhead">
          <strong class="tb-red">{{ translations.WORLD_PREMIERE }}</strong>
          {{ item.date_full }}
        </p>
        <p v-if="item.status === 'premiere'" class="subhead">
          <strong class="tb-red">{{ translations.PREMIERE }}</strong>
          {{ item.date_full }}
        </p>
        <p v-if="item.status === 'wiederaufnahme'" class="subhead">
          <strong class="tb-red">{{ translations.WIEDERAUFNAHME }}</strong>
          {{ item.date_full }}
        </p>
        <p v-if="item.status === 'einfuehrungsmatinee'" class="subhead">
          <strong class="tb-red">{{ translations.EINFUEHRUNGSMATINEE }}</strong>
          {{ item.date_full }}
        </p>
        <h2 class="teaser-headline">{{ item.title }}</h2>
        <div v-html="item.description"></div>
        <p class="subtitle-gray">
          <em>{{ item.genre_names.join(', ') }}</em>
        </p>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    css_classes: String
  },
  computed: {
    translations () {
      return this.$store.state.DjangoApp.settings.TRANSLATIONS
    },
    dir_static () {
      return this.$store.state.DjangoApp.settings.STATIC_URL
    }
  }
}
</script>
