<template>
  <div class="cell">
    <cell-spacing />
    <season-switch />
    <cell-spacing />
    <eilmeldung />
    <repertoire-events></repertoire-events>
  </div>
</template>
<script>
import cellSpacing from '@/components/cellSpacing'
import seasonSwitch from '@/components/filter/season'
import repertoireEvents from '@/components/repertoire'
import eilmeldung from '@/components/eilmeldung'

export default {
  components: {
    cellSpacing,
    seasonSwitch,
    repertoireEvents,
    eilmeldung
  },
  mounted () {
    this.$store.dispatch('fetchRepeitoreList')
  }
}
</script>
