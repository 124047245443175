const zone = 'Europe/Berlin'
const moment = require('moment-timezone')
moment.tz.setDefault(zone)

export { moment }

/**
 * Increment month in date by 1
 * @param  {Date} date date to increment
 * @return {Date} incremented date
 */
export function getDateBeginningOfTheNextMonth (date) {
  return moment(date).add(1, 'months').startOf('month')
}
/**
 * Test if the date is in defined period.
 * @param  {String} dateString tested date
 * @param  {String} dateStringFrom period start
 * @param  {String} dateStringTo period end
 * @return {Boolean} is date in range
 */
export function isInDateRange (dateString, dateStringFrom, dateStringTo) {
  return dateStringFrom <= dateString && dateStringTo >= dateString
}
/**
 * Test if the date is between defined period.
 * @param  {String} dateString tested date
 * @param  {String} dateStringFrom period start
 * @param  {String} dateStringTo period end
 * @return {Boolean} is date in range
 */
export function isBetweenDateRange (dateString, dateStringFrom, dateStringTo) {
  return dateStringFrom < dateString && dateStringTo > dateString
}
/**
 * Return date in the beginning of the month.
 * @param  {Date} date
 * @return {Date}
 */
export function getDateBeginningOfTheMonth (date) {
  return moment(date).startOf('month')
}
/**
 * Return date beginning of the current month.
 * @return {Date}
 */
export function getTodayBeginningOfTheMonth () {
  return moment().startOf('month')
}
/**
 * Month filter. Check if the date is in range of specific Month.
 * Month is considered as beginning of the month until the last day of this month.
 * @param  {Date} date event date.
 * @param  {Date} filterMonthDate date from month filter.
 * @return {Boolean}
 */
export function monthFilterExactMonth (date, filterMonthDate) {
  const dateFrom = getDateBeginningOfTheMonth(filterMonthDate)
  const dateTo = getDateBeginningOfTheNextMonth(filterMonthDate)
  return isBetweenDateRange(moment(date), dateFrom, dateTo)
}
/**
 * Is date in the future.
 * @param  {String} date
 * @return {Boolean}
 */
export function isFuture (date) {
  return moment(date).isAfter()
}
/**
 * Generates object array of months starting on first day of the month,
 * within specified date range.
 * @param  {Array} monthArrayNames String names for months.
 * @param  {String} dateFrom Date range from
 * @param  {String} dateTo Date range to
 * @return {Array}
 */
export function generateMonthsArrayBeginningOfTheMonth (monthArrayNames, dateFrom, dateTo) {
  let dateFromTimestamp = getDateBeginningOfTheMonth(dateFrom)
  let dateToTimestamp = getDateBeginningOfTheMonth(dateTo)
  let months = []
  while (dateFromTimestamp <= dateToTimestamp) {
    let date = dateFromTimestamp
    let name = monthArrayNames[dateFromTimestamp.month()]
    months.push({
      name,
      date
    })

    dateFromTimestamp = getDateBeginningOfTheNextMonth(dateFromTimestamp)
  }
  return months
}

export function generateMonthsArrayForSeasons (seasons, monthArrayNames) {
  return generateMonthsArrayBeginningOfTheMonth(
    monthArrayNames,
    Math.min.apply(null, seasons.map(s => moment(s.start))),
    Math.max.apply(null, seasons.map(s => moment(s.end)))
  )
}

export function isInMonth (date, monthDate) {
  return getDateBeginningOfTheMonth(date).toString() === getDateBeginningOfTheMonth(monthDate).toString()
}
