<template>
  <div class="cell">
    <div class="grid-x grid-margin-x align-center">
      <div class="cell">
        <div v-if="storeCalendarItems.length === 0" class="grid-x align-center">
          <loading/>
        </div>
        <div v-if="storeCalendarItems.length > 0" :class="{'fade-in-out-animation': animate}" class="event-list">
          <item-calendar
            v-for="item in calendarItems"
            :key="item.id"
            :item="item"
            @openModal="openModal"
          />
        </div>
      </div>
    </div>
    <load-more-items v-if="this.list.length > showItems" @show-more="showMore"/>
    <ext-link-modal v-if="this.modalOpened" :url="this.externalUrl" />
  </div>
</template>
<script>
import itemCalendar from '@/components/calendar/item'
import { monthFilterExactMonth, isFuture } from '@/helpers.js'
import loadMoreItems from '@/components/loadMoreItems'
import loading from '@/components/loading'
import extLinkModal from '@/components/extLinkModal'

export default {
  layout: 'calendar',
  data () {
    return {
      modalOpened: false,
      externalUrl: null
    }
  },
  methods: {
    showMore () {
      this.$store.state.events.query.showItems = 1000
      this.$store.dispatch('showMoreItems')
    },
    openModal (link) {
      this.modalOpened = true
      this.externalUrl = link
      document.querySelector('html, body').classList.add('no-scroll')
    },
    closeModal () {
      this.modalOpened = false
      document.querySelector('html, body').classList.remove('no-scroll')
    }
  },
  components: {
    itemCalendar,
    loadMoreItems,
    loading,
    extLinkModal
  },
  computed: {
    animate () {
      return this.$store.state.events.animate
    },
    storeCalendarItems () {
      return this.$store.state.events.list
    },
    calendarItems () {
      return this.list.slice(0, this.showItems)
    },
    showItems () {
      return this.$store.state.events.query.showItems
    },
    list () {
      return this.$store.state.events.list
        .filter((item) => monthFilterExactMonth(item.date, this.queryMonth) && isFuture(item.date))
        .filter(
          (item) =>
            !this.categoryFilter ||
            item.categories.includes(this.categoryFilter)
        )
        .filter(
          (item) =>
            !this.premiereFilter || item.is_premiere === this.premiereFilter
        )
    },
    seasonFilter () {
      return this.$store.state.events.query.season
    },
    categoryFilter () {
      return this.$store.state.events.query.category
    },
    premiereFilter () {
      return this.$store.state.events.query.is_premiere
    },
    queryMonth () {
      return this.$store.state.events.query.month
    }
  }
}
</script>
