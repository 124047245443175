<template>
  <div class="cell">
    <cell-spacing />
    <month-switch />
    <cell-spacing />
    <eilmeldung />
    <calendar-events />
  </div>
</template>
<script>
import calendarEvents from '@/components/calendar'
import cellSpacing from '@/components/cellSpacing'
import monthSwitch from '@/components/filter/month'
import eilmeldung from '@/components/eilmeldung'

export default {
  components: {
    cellSpacing,
    monthSwitch,
    calendarEvents,
    eilmeldung
  },
  mounted () {
    this.$store.dispatch('fetchEventList')
  }
}
</script>
